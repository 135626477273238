import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {get_all_quotation} from "../../redux/quotation";
import { theme } from "../../config";
import Whatsapp from "../../components/whatsapp";

const Quotaions = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const _get_all_quotation = useSelector((state) => state.quotation_module._get_all_quotation);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [isLoading, setIsLoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [n_page, setNPage] = useState(0);

    useEffect(() => {
        setIsLoading(true);
        if (_get_all_quotation.data && _get_all_quotation.data.datos) {
            setDatas(_get_all_quotation.data.datos);
            setNPage(_get_all_quotation.data.n_page);
            setIsLoading(false);
        }
    }, [_get_all_quotation, isLoading]);

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setDatas(_get_all_quotation.data.datos);
            return;
        }
        setDatas([]);
        let value = e.target.value;
        value = value.trim();
        let re = new RegExp(value, "i");
        // eslint-disable-next-line
        let filtro = _get_all_quotation.data.datos.filter(element => {
            if ((element.codigo.match(re)) || (element.estado.match(re))) {
                return element;
            }
        })
        setDatas(filtro);
    }

    const [page, setPage] = useState(1);

    function more_data() {
        let count = page + 1;
        setPage(count)
        dispatch(get_all_quotation(token, count));
    }

    function less_data() {
        let count = page - 1;
        setPage(count)
        dispatch(get_all_quotation(token, count));
    }

    return (
        <div>
            <section className="product_section p_section1">
                <div className="container">
                    <div class="middel_right mt-2">
                        <div class="search_btn">
                            {/* eslint-disable-next-line */}
                            <a><i class="ion-ios-search-strong"></i></a>
                            <div class="dropdown_search">
                                <form action="#">
                                    <input placeholder="Cotización" type="text" onChange={handleSearch}/>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <h3 className="text-titulo">Cotizaciones</h3>
                    </div>
                    <div className="row">   
                        <div className="col-12">
                            <div className="product_area" style={{marginBottom: '10mm'}}> 
                                <div className="tab-content" style={{display: "contents"}}>
                                    <div className="tab-pane fade show active" id="featured" role="tabpanel">
                                        <div className="product_container">
                                            <div className="custom-row product_column1">
                                                {datas && (
                                                    datas.map((x) => (
                                                        <div className="col-lg-2 col-md-3 col-sm-6 col-12 p-1" onClick={() => {history.push(`/cotizacion/${x.id}`)}} style={{cursor: 'pointer'}}>
                                                            <div className="single_product">
                                                                <div className="product_thumb text-center">
                                                                    {/* eslint-disable-next-line */}
                                                                    <a className="primary_img"><img src="../../../../assets/img/logo/solicitud-de-cotizacion.png" alt="" style={{width: '50%'}}/></a>
                                                                </div>
                                                                <div className="product_content">
                                                                    <div className="tag_cate">
                                                                        <h2>{x.codigo}</h2>
                                                                        <span style={{color: theme(x.estado.toUpperCase())}}>{x.estado.toUpperCase()}</span>
                                                                    </div>
                                                                    <div className="product_hover">
                                                                        <div className="product_desc">
                                                                            <span style={{color: theme(x.estado.toUpperCase())}}>{x.estado.toUpperCase()}</span>
                                                                            <p>{x.fecha_creacion}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="shop_toolbar t_bottom">
                                    <div class="pagination">
                                        <ul>
                                            {(page > 1 && page <= n_page) &&
                                                <li>
                                                    {/* eslint-disable-next-line */}
                                                    <a onClick={() => {less_data()}}>{'<<'}</a>
                                                </li>
                                            }
                                            <li class="current">{page}</li>
                                            {(page >= 1 && page < n_page) &&
                                                <li>
                                                    {/* eslint-disable-next-line */}
                                                    <a onClick={() => {more_data()}}>{'>>'}</a>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </section>
            <Whatsapp/>
        </div>
    );
};

export default Quotaions;
