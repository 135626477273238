import React from "react";
import "./whatsapp.css";

const Whatsapp = () => {
  return (
    <div className="text-right">
      <a href="https://wa.me/+573176654051?text=Hola,%20me%20pueden%20ayudar%20con%20m%C3%A1s%20informaci%C3%B3n" className="whatsapp-btn" target="_blank" title="Comuníquese con nosotros">
          <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  );
};

export default Whatsapp;