import React, {useEffect, useContext} from "react";
import Navbar from "../components/navbar";
import {useHistory} from "react-router-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {get_all_family} from '../redux/families';
import Catalogues from "./catalogues/index";
import Products from "./catalogues/products";
import Item from "./catalogues/item";
import Orders from "./orders";
import ItemOrder from "./orders/item";
import Quotations from "./quotations";
import ItemQuotation from "./quotations/item";
import Carts from "./carts";
import {Buffer} from 'buffer';
import {
    USERNAME,
    PASSWORD,
} from "../config";
import { get_all_banner_image } from "../redux/banner_images";
import { get_all_city } from "../redux/cities";
import { get_all_quotation } from "../redux/quotation";
import { get_all_order } from "../redux/order";
import { SocketContext, TYPE_UPDATE_MESSAGE, TYPE_CREATE_MESSAGE } from "../config/ws-client";
import { get_all_groups } from "../redux/users";
import ActivateAccount from "./users/activate-account";
import ResetPassword from "./users/reset-password";
import { get_all_payment_type } from "../redux/payment_type";

const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _data_login = useSelector((state) => state.users_module.login);
    // const _session_data = useSelector((state) => state.users_module._session_data);
    /* const [permisos, setPermissions] = useState([]);
    
    const _get_notification = useSelector((state) => state.quotation_module._get_notification);
    const _get_notification_order = useSelector((state) => state.order_module._get_notification_order);
    const [token, setToken] = useState("") */
    
    const {socket} = useContext(SocketContext);
    var day = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }
    var date = year + '-' + month + '-' + day;

    useEffect(() => {
        let token = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
        if (_data_login.status >= 200) {
            token = _data_login.data.token
            dispatch(get_all_city(token));
            dispatch(get_all_groups(token));
            dispatch(get_all_quotation(token, 1));
            dispatch(get_all_order(token, 1));
            dispatch(get_all_payment_type(token));
        }

        dispatch(get_all_family(token));
        dispatch(get_all_banner_image(token));

    }, [dispatch, _data_login, history, date]);

    useEffect(() => {
        handleMessageWs();
        // eslint-disable-next-line
    }, [_data_login, socket])

    const handleMessageWs = () => {
        if (socket) {
            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === TYPE_UPDATE_MESSAGE) {
                    update_message(data, socket)
                    return;
                }

                if (data.type === TYPE_CREATE_MESSAGE) {
                    create_message(data, socket)
                }
            }
        }
    }


    const update_message = (data, socket) => {
        if (_data_login && _data_login.status >= 200) {
            dispatch(get_all_quotation(_data_login.data.token, 1));
        }
    }

    const create_message = (data, socket) => {
        if (_data_login && _data_login.status >= 200) {
            dispatch(get_all_quotation(_data_login.data.token, 1));
            dispatch(get_all_order(_data_login.data.token, 1));
        }
    }


    return (
        <Router>
            <div className="header-navbar-shadow"/>
            <Navbar/>
            <Switch>
                <Route path="/" exact>
                    <Catalogues/>
                </Route>
                <Route path="/familia/:family" exact>
                    <Products/>
                </Route>
                <Route path="/ver/:id" exact>
                    <Item/>
                </Route>
                <Route path="/mis-cotizaciones" exact>
                    <Quotations/>
                </Route>
                <Route path="/cotizacion/:id" exact>
                    <ItemQuotation/>
                </Route>
                <Route path="/mis-fabricaciones" exact>
                    <Orders/>
                </Route>
                <Route path="/fabricacion/:id" exact>
                    <ItemOrder/>
                </Route>
                <Route path="/carrito-compra" exact>
                    <Carts/>
                </Route>
                <Route exact path="/activar-cuenta/:user_id/:token/">
                    <ActivateAccount/>
                </Route>
                <Route exact path="/confirmar-contrasena/:uid/:token/">
                    <ResetPassword/>
                </Route>
            </Switch>
        </Router>
    );
};
export default Index;
