import axios from "axios";
import {
    URLAPI,
    PAYMENT_TYPE_PATH,
} from "../config";
import {LOGOUT} from './users';

const init = {
    _get_all_payment_type: {
        data: [],
        status: 0,
        message: {},
    },
}

const GET_ALL_PAYMENT_TYPE_SUCCESS = "GET_ALL_PAYMENT_TYPE_SUCCESS";

export const payment_type_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                _get_all_payment_type: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const get_all_payment_type = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${PAYMENT_TYPE_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_PAYMENT_TYPE_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_PAYMENT_TYPE_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_PAYMENT_TYPE_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}


export const clear_status_payment_type = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}
